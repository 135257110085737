import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <i className="fas fa-anchor" />
    <h1 className="title">404</h1>
    <p className="bandDescription">Hark! You have hit rock bottom and that page doesn't exist.<br/>At least the dancing baby is still here.</p>
  </Layout>
)

export default NotFoundPage